import React from "react";
import PageLayout from "../components/Shared/PageLayout";
import dynamic from "next/dynamic";
import { Stack } from "@mui/material";
import SPPageLoader from "../components/SPComponents/SPPageLoader";

const LoginMainLayout = dynamic(() => import("../components/Pages/Login/Public/Login_MainLayout"), {
    suspense: false,
    ssr: false,
    loading: () => <SPPageLoader in={true} />,
})

export default function LoginPage() {
    return <Stack
        justifyContent={"center"}
        alignItems="center"
        id="login-container"
        style={{
            paddingTop: "50px",
            paddingBottom: "30px",
            height: "auto",
            minHeight: "100vh",
            background: "white",
            maxWidth: 1920,
            transition: 'all .5s ease-in-out',
            backgroundImage: "url('/supercontent/login.jpg')",
            backgroundRepeat: "no-repeat",
            //backgroundPosition: "left 100% bottom",
            backgroundPosition: "center",
            backgroundSize: "cover",
        }}>
        <LoginMainLayout />
    </Stack>;
}

LoginPage.metaData = {
    title: 'Superplay - Login'
}